import * as React from 'react';
import './index.css';

const currentYear = new Date().getFullYear();

function Footer(): React.ReactElement<{}> {
    return (
        <div>
            <footer className='app--footer__re-skin-light'>
                <div>
                    <span className='copyright__re-skin-light'>
                        {'GroundTruth '}&copy; {currentYear}. All rights reserved.{' '}
                    </span>
                    <span className='privacy__re-skin-light'>
                        <a href='https://www.groundtruth.com/privacy-policy/' target='_blank' rel='noreferrer'>
                            Privacy Policy
                        </a>
                    </span>
                </div>
            </footer>
        </div>
    );
}

export default Footer;
