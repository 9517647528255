/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum SortColumns {
    LOCATION_GROUP_NAME = 'location_group_name',
    LOCATION_GROUP_ID = 'location_group_id',
    START_DATE = 'started_at',
    STATUS = 'status'
}
