import React, {
    useState, useEffect, useRef, MouseEvent, KeyboardEvent
} from 'react';

import { ReactComponent as ProfileIcon } from '../../../images/profile-icon.svg';
import { ReactComponent as ChevronUp } from '../../../images/chevron-up24x24.svg';
import { ReactComponent as ChevronDown } from '../../../images/chevron-down24x24.svg';
import logout from '../../../utils/logout';
import './index.css';

const HELP_PAGE = 'https://help.groundtruth.com/hc/en-us/articles/26237328683667-Console-Help-Center';

interface ProfileMenuProps {
    userName?: string
}

export default function ProfileMenu(props: ProfileMenuProps) {
    const { userName } = props;
    const [isOpen, setIsOpen] = useState(false);
    const profileMenuRef = useRef<HTMLDivElement>(null);

    const onKeyDownLogout = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            logout();
        }
    };

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (profileMenuRef?.current && !profileMenuRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick as any);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick as any);
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const openHelp = () => {
        window.open(HELP_PAGE, '_blank');
    };

    return (
        <div className='profile-menu' data-testid='profile-menu' ref={profileMenuRef}>
            <button
                type='button'
                data-testid='toggle-button'
                className='profile-menu__toggle'
                onClick={toggleDropdown}
            >
                <ProfileIcon className='profile-menu__icon' />
                <span className='profile-menu__username'>{ userName }</span>
                { isOpen && <ChevronUp className='profile-menu__chevron' /> }
                { !isOpen && <ChevronDown className='profile-menu__chevron' /> }
            </button>
            {
                isOpen && (
                    <div className='profile-menu__items' data-testId='profile-menu__items'>
                        <div
                            role='button'
                            tabIndex={0}
                            className='profile-menu__item'
                            onClick={openHelp}
                            onKeyDown={() => {}}
                        >
                            Help
                        </div>
                        <div className='profile-menu__item-divider' />
                        <div
                            role='button'
                            tabIndex={0}
                            className='profile-menu__item'
                            onClick={logout}
                            onKeyDown={onKeyDownLogout}
                        >
                            Sign out
                        </div>
                    </div>
                )
            }
        </div>
    );
}

ProfileMenu.defaultProps = {
    userName: 'Welcome'
};
