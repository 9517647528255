import React from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

import { ReactComponent as GtLogo } from '../../../images/groundtruth-logo.svg';
import { ReactComponent as GtLogoSmall } from '../../../images/groundtruth-logo-small.svg';
import { ReactComponent as GtLogoText } from '../../../images/groundtruth-logo-text.svg';
import { ReactComponent as GtLogoTextSmall } from '../../../images/groundtruth-logo-text-small.svg';
// import { getThemeImageSrc, THEME_IMAGES } from '../../../theme';
import './index.css';

interface AppLogoProps {
    onLogoClick?: () => void;
}

export default function AppLogo({ onLogoClick = () => {} }: AppLogoProps) {
    const isMobile = useMediaQuery('(max-width:800px)');

    return (
        <Link to='/' onClick={onLogoClick}>
            <div className='app-logo-main' data-testid='app-logo-main'>
                <div className='app-logo__img-container'>
                    { isMobile ? <GtLogoSmall /> : <GtLogo /> }
                </div>
                <div className='app-logo-main__text'>
                    { isMobile ? <GtLogoTextSmall /> : <GtLogoText /> }
                    <div>Insights</div>
                </div>
            </div>
        </Link>
    );
}
AppLogo.defaultProps = {
    onLogoClick: () => {}
};
