import React, { useState } from 'react';
import { Drawer } from '@mui/material';

import { ReactComponent as MenuIcon } from '../../images/menu-icon24x24.svg';
import { ReactComponent as MenuIconOpen } from '../../images/menu-icon24x24-open.svg';
import AvailableApps from '../availableApps';
import './index.css';

interface HamburgerMenuProps {
    userApps?: string
}

function HamburgerMenu(props: HamburgerMenuProps): React.ReactElement | null {
    const { userApps } = props;
    const [open, setOpen] = useState(false);

    const toggleModal = (val: boolean) => {
        setOpen(val);
    };

    return userApps ? (
        <>
            <button type='button' className='ham-menu--wrapper' onClick={() => toggleModal(true)}>
                {open
                    ? <MenuIconOpen className='ham-menu--menu-icon' />
                    : <MenuIcon className='ham-menu--menu-icon' />}
            </button>

            <Drawer
                open={open}
                onClose={() => toggleModal(false)}
            >
                <div className='ham-menu--drawer-wrapper'>
                    <AvailableApps authApps={userApps} />
                </div>
            </Drawer>
        </>
    ) : null;
}

HamburgerMenu.defaultProps = {
    userApps: ''
};

export default HamburgerMenu;
