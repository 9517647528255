import * as React from 'react';
import {
    createContext, ReactNode, useReducer
} from 'react';

const getStoredSortRule = () => {
    const initialSortRule = localStorage.getItem('sortRule');
    return initialSortRule ? JSON.parse(initialSortRule) : null;
};

const initialState = {
    sortRule: getStoredSortRule()
};

const store = createContext({});
const { Provider } = store;

enum StateActionType {
    SET_LOCATION_GROUP_DATA = 'SET_LOCATION_GROUP_DATA',
    SET_LOCATION_GROUPS_DATA = 'SET_LOCATION_GROUPS_DATA',
    SET_LOADING = 'SET_LOADING',
    SET_SEARCH_QUERY = 'SET_SEARCH_QUERY',
    SET_SORT_RULE = 'SET_SORT_RULE'
}

interface StateAction {
    type: StateActionType;
    payload: any;
}

interface StateProviderProps {
    children: ReactNode;
}

const setLocationGroupData = (state: any, action: StateAction) => ({
    ...state,
    campaignData: action.payload
});

const setLocationGroupsData = (state: any, action: StateAction) => ({
    ...state,
    campaignsData: action.payload
});

const setLoading = (state: any, action: StateAction) => ({
    ...state,
    isLoading: action.payload
});

const setSearchQuery = (state: any, action: StateAction) => ({
    ...state,
    searchQuery: action.payload
});

const setSortRule = (state: any, action: StateAction) => {
    localStorage.setItem('sortRule', JSON.stringify(action.payload));
    return {
        ...state,
        sortRule: action.payload
    };
};

function StateProvider(props: StateProviderProps) {
    const { children } = props;
    const [state, dispatch] = useReducer((_state: any, action: StateAction) => {
        switch (action.type) {
            case StateActionType.SET_LOCATION_GROUP_DATA:
                return setLocationGroupData(_state, action);
            case StateActionType.SET_LOCATION_GROUPS_DATA:
                return setLocationGroupsData(_state, action);
            case StateActionType.SET_LOADING:
                return setLoading(_state, action);
            case StateActionType.SET_SEARCH_QUERY:
                return setSearchQuery(_state, action);
            case StateActionType.SET_SORT_RULE:
                return setSortRule(_state, action);
            default:
                throw new Error(`Invalid action '${action.type}' dispatched`);
        }
    }, initialState);

    return <Provider value={{ state, dispatch }}>{children}</Provider>;
}

export { store, StateProvider, StateActionType };
