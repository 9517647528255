import React, {
    useState, useContext, useEffect
    // ChangeEvent
} from 'react';
import {
    useNavigate, useLocation
    // , useSearchParams
} from 'react-router-dom';

import HamburgerMenu from '../hamburgerMenu';
import AppLogo from './appLogo';
import { store, StateActionType } from '../../store';
import Loader from './loader';
// import SearchBarV2 from '../searchBarV2';
import ProfileMenu from './profileMenu';

import { getUserInfo } from '../../api';
import type { UserInfo } from '../../client';

import './index.css';

export default function Header() {
    const location = useLocation();
    // const [searchParams] = useSearchParams();
    const globalStore: any = useContext(store);
    // const searchValue = globalStore?.state?.searchQuery || searchParams.get('q') || '';
    // const [searchOpen, setSearchOpen] = useState(false);
    const [userInfo, setUserInfo] = useState<UserInfo>();
    const { dispatch } = globalStore;
    const navigate = useNavigate();

    const fetchUserInfo = async () => {
        let user = {};
        try {
            user = await getUserInfo();
        } catch (error) {
            console.log(error);
        }
        setUserInfo(user);
    };

    useEffect(() => {
        fetchUserInfo();
    }, []);

    const handleSearch = (_val: string) => {
        localStorage.removeItem('page');
        navigate({
            pathname: '/',
            search: `?q=${_val}`
        });
    };

    // const onSearchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    //     const val = event.target.value;
    //     dispatch({ type: StateActionType.SET_SEARCH_QUERY, payload: val });
    //     if (location.pathname === '/') handleSearch(val);
    // };

    const onClearSearchValue = () => {
        dispatch({ type: StateActionType.SET_SEARCH_QUERY, payload: '' });
        if (location.pathname === '/') handleSearch('');
    };

    // const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    //     if (event.key === 'Enter') {
    //         handleSearch(searchValue);
    //     }
    // };

    const displayNavButton = () => <HamburgerMenu userApps={userInfo?.authorized_apps} />;

    return (
        <header className='app-header'>
            <div className='app-header__left-group'>
                {displayNavButton()}
                <Loader isLoading={globalStore.state.isLoading} />
                <AppLogo onLogoClick={onClearSearchValue} />
            </div>
            <div className='app-header__search-group'>
                {/* <SearchBarV2
                    isOpen={searchOpen}
                    setIsOpen={setSearchOpen}
                    searchValue={searchValue}
                    onSearchValueChange={onSearchValueChange}
                    onClearSearchValue={onClearSearchValue}
                    onSearch={handleSearch}
                    onKeyDown={handleKeyDown}
                /> */}
                <ProfileMenu userName={userInfo?.first_name} />
            </div>
        </header>
    );
}
