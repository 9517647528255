import {
    Table,
    TableCell,
    TableHead,
    TableSortLabel,
    TableRow,
    TableBody,
    TableContainer,
    Select,
    MenuItem,
    Pagination,
    SelectChangeEvent,
    FormControl
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from 'react';
import { capitalize } from 'lodash';
import './index.css';
import { AuthorizedLGs, SortOrder } from '../../../client';
import { sanitizeHTML, getDatefromTimestamp } from '../../../utils';
import { ReactComponent as SortIcon } from '../../../images/arrow-down.svg';

type SortRule = {
    id: number;
    isActive: boolean;
    direction: string;
    name: string;
};

type TableViewProps = {
    locationGroups: AuthorizedLGs[];
    navigateToLG: (locationGroupId: Number) => void;
    sortRules: SortRule[];
    onSortIconClick: (i: number) => void;
    rowCount: number;
    onPageChange: (page: number, rowsPerPage: number) => void;
    isLoading: boolean;
    showNoLGsFound: boolean;
};

const statusColors: { [key: string]: string } = {
    Enabled: 'var(--color-active-status)',
    Pending: 'var(--color-secondary)',
    Disabled: 'var(--color-paused-status)',
    Expired: 'var(--color-expired-status)',
    default: 'var(--color-on-primary)'
};
const getStatusColor = (status: string) => statusColors[status] || statusColors.default;

const tableStyles = {
    table: {
        borderCollapse: 'separate',
        borderSpacing: '0 15px',
        padding: '0px 80px 80px 80px'
    },
    headerCell: {
        border: 'none',
        padding: '10px 10px 0 16px'
    },
    largeWidth: {
        width: '30vw'
    },
    medium: {
        width: '22vw'
    },
    end: {
        paddingLeft: '16px !important'
    },
    smallWidth: {
        width: '12vw'
    },
    tableData: {
        borderLeft: '1px solid'
    },
    activeHeader: {
        fontWeight: 800,
        '.MuiTableSortLabel-iconDirectionAsc .common-table-icon': {
            color: 'var(--color-on-primary) !important'
        },
        '.MuiTableSortLabel-iconDirectionDesc .common-table-icon': {
            color: 'var(--color-on-primary) !important'
        }
    },
    loading: {
        opacity: 0.1
    },
    defaultSortLabel: {
        '&:hover': {
            color: 'inherit'
        }
    }
};

type SortIconsProps = {
    className: string;
};

function SortIcons(props: SortIconsProps) {
    const { className } = props;
    return (
        <div className={`${className} table-view--icon-container`}>
            <SortIcon className='common-table-icon' />
        </div>
    );
}

export default function TableView(props: TableViewProps) {
    const {
        locationGroups,
        navigateToLG,
        sortRules,
        onSortIconClick,
        rowCount,
        onPageChange,
        isLoading,
        showNoLGsFound
    } = props;
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | React.ChangeEvent, newPage: number) => {
        setPage(newPage);
        onPageChange(newPage, rowsPerPage);
        localStorage.setItem('page', newPage.toString());
    };

    const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
        const val = +event.target.value;
        setRowsPerPage(val);
        setPage(1);
        onPageChange(1, val);
        localStorage.setItem('rowsPerPage', val.toString());
    };

    React.useEffect(() => {
        if (rowCount < rowsPerPage) {
            setPage(1);
        }
        const pageFromStorage = localStorage.getItem('page');
        setPage(pageFromStorage ? parseInt(pageFromStorage, 10) : 1);
        const rowsPerPageFromStorage = localStorage.getItem('rowsPerPage');
        setRowsPerPage(rowsPerPageFromStorage ? parseInt(rowsPerPageFromStorage, 10) : 10);
        onPageChange(
            pageFromStorage ? parseInt(pageFromStorage, 10) : 1,
            rowsPerPageFromStorage ? parseInt(rowsPerPageFromStorage, 10) : 10
        );
    }, [rowCount]);

    const SortableTableCell = React.memo((_props: { header: string; sortIndex: number }) => {
        const { header, sortIndex } = _props;
        return (
            <TableCell
                align={sortIndex > 1 ? 'center' : 'left'}
                sx={[tableStyles.headerCell, sortRules[sortIndex]?.isActive && tableStyles.activeHeader]}
            >
                <TableSortLabel
                    data-testid={`${header.replace(' ', '-')}-test-header`}
                    active={sortRules[sortIndex]?.isActive}
                    direction={sortRules[sortIndex]?.direction as SortOrder}
                    onClick={() => onSortIconClick(sortIndex)}
                    IconComponent={SortIcons}
                    disabled={isLoading}
                >
                    {header}
                </TableSortLabel>
            </TableCell>
        );
    });

    return (
        <>
            <TableContainer className='common-table'>
                <Table sx={[tableStyles.table, isLoading && tableStyles.loading]}>
                    <TableHead>
                        <TableRow>
                            <SortableTableCell header='Location Group Name' sortIndex={0} />
                            <SortableTableCell header='Location Group ID' sortIndex={1} />
                            <SortableTableCell header='Status' sortIndex={2} />
                            <SortableTableCell header='Start' sortIndex={5} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {showNoLGsFound && (
                            <TableRow>
                                <TableCell colSpan={6}>No Location Group(s) found</TableCell>
                            </TableRow>
                        )}
                        {locationGroups.map((row: AuthorizedLGs) => (
                            <TableRow
                                onClick={() => !isLoading && navigateToLG(row.id)}
                                key={row.id}
                            >
                                <TableCell sx={[tableStyles.largeWidth]}>
                                    <div className='table-cell-label'>{sanitizeHTML(row.name)}</div>
                                    <a
                                        className='table-row--overlay-link'
                                        href={`location_group/${row.id}`}
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        &nbsp;
                                    </a>
                                </TableCell>
                                <TableCell sx={[tableStyles.medium]}>
                                    <div className='table-cell-label'>
                                        {sanitizeHTML(row.id.toString())}
                                    </div>
                                </TableCell>
                                <TableCell
                                    align='center'
                                    sx={[
                                        tableStyles.smallWidth,
                                        {
                                            '& .status-label': {
                                                fontWeight: 600,
                                                color: getStatusColor(capitalize(row.status))
                                            }
                                        }
                                    ]}
                                >
                                    <div className='table-cell-label status-label'>{capitalize(row.status)}</div>
                                </TableCell>
                                <TableCell align='center' sx={[tableStyles.smallWidth, tableStyles.end]}>
                                    <div>{getDatefromTimestamp(row.created)}</div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {!isLoading && (
                <div className='table-view--pagination-desktop'>
                    <FormControl sx={{ m: 1 }}>
                        <Select
                            sx={{ m: 1 }}
                            id='row-per-page-select-helper'
                            data-testid='test-row-per-page-select-helper'
                            value={rowsPerPage.toString()}
                            onChange={handleChangeRowsPerPage}
                            variant='standard'
                            disabled={isLoading}
                            IconComponent={KeyboardArrowDownIcon}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                        </Select>
                    </FormControl>
                    <span className={`${isLoading && 'table-view--loading'}`}>per page</span>
                    <button
                        className='MuiPaginationItem-root pagination-button'
                        type='button'
                        onClick={(e) => handleChangePage(e, 1)}
                        disabled={!(Math.ceil(rowCount / rowsPerPage) > 1 && page !== 1)}
                    >
                        First
                    </button>
                    <Pagination
                        page={page}
                        data-testid='test-row-per-page-change-helper'
                        onChange={handleChangePage}
                        count={Math.ceil(rowCount / rowsPerPage)}
                        disabled={isLoading}
                    />
                    <button
                        type='button'
                        className='MuiPaginationItem-root pagination-button'
                        onClick={(e) => handleChangePage(e, Math.ceil(rowCount / rowsPerPage))}
                        disabled={page === Math.ceil(rowCount / rowsPerPage)}
                    >
                        Last
                    </button>
                </div>
            )}
        </>
    );
}
