import React from 'react';
import LocationGroupCard from '../locationGroupCard';
import { AuthorizedLGs } from '../../../client';
import './index.css';

type MobileViewProps = {
    locationGroups: AuthorizedLGs[];
    isLoading: boolean;
    loadMore: () => void;
    showNoLGsFound: boolean;
};

export default function MobileView(props: MobileViewProps) {
    const {
        locationGroups, isLoading, loadMore, showNoLGsFound
    } = props;

    const handleScroll = (e: any) => {
        const bottom = Math.ceil(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight;
        if (bottom) {
            loadMore();
        }
    };

    return (
        <div
            className={`mobile-view--container ${isLoading && 'mobile-view-opaque-container'}`}
            onScroll={handleScroll}
        >
            {showNoLGsFound
                && <div className='mobile-view--no-location-groups-found'>No location group(s) found</div>}
            {locationGroups.map((c: AuthorizedLGs) => (
                <LocationGroupCard key={c.id} locationGroup={c} />
            ))}
        </div>
    );
}
