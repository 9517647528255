import { createTheme } from '@mui/material/styles';
import themeConfig from './themeConfig.json';

const STANDARD_THEME = 'standard';

export const THEME_IMAGES = {
    LOGO: 'logo',
    LOGIN_LOGO: 'loginLogo',
    LOGIN_BACKGROUND: 'loginBackground',
    FAVICON: 'favicon'
};

const PAGE_TITLE = {
    STANDARD: 'GT Insights',
    WHITE_LABEL: 'Campaign Report'
};

interface ThemePalette {
    'brand-id-primary-color': string,
    'brand-id-map-pins-color': string
}

interface ThemeImages {
    logo: string,
    loginBackground: string, // Standard theme uses images from UL login page
    loginLogo: string, // Standard theme uses images from UL login page
    favicon: string
}

interface Theme {
    palette: ThemePalette,
    images: ThemeImages,
    name: string
}

interface ThemeConfig {
    standard: Theme;
}

export const getTenantTheme = (): Theme => {
    const name = STANDARD_THEME;
    const theme = themeConfig[name as keyof ThemeConfig] || themeConfig[STANDARD_THEME];

    return theme;
};

const setPaletteVariables = (theme: Theme) => {
    const { palette } = theme;

    Object.keys(palette).forEach((key) => {
        document.documentElement.style.setProperty(`--${key}`, palette[key as keyof ThemePalette]);
    });
};

export const getThemeImageSrc = (imageKey: string, theme?: Theme) => {
    const { images, name } = theme || getTenantTheme();
    const imgSrc = images[imageKey as keyof ThemeImages];
    if (imgSrc) {
        return name === STANDARD_THEME ? imgSrc : process.env.PUBLIC_URL + imgSrc;
    }
    return '';
};

const setThemeFavicon = (theme: Theme) => {
    const favicon = document.querySelector('#favicon');
    if (favicon) {
        favicon.setAttribute('href', getThemeImageSrc(THEME_IMAGES.FAVICON, theme));
    }
};

export const setTenantTheme = () => {
    const theme = getTenantTheme();
    setPaletteVariables(theme);
    setThemeFavicon(theme);
    document.title = theme.name === STANDARD_THEME ? PAGE_TITLE.STANDARD : PAGE_TITLE.WHITE_LABEL;
};

const getMuiTheme = () => createTheme({
    palette: {
        primary: {
            main: '#20d2b5'
        },
        text: {
            primary: '#0a3f36'
        }
    }
});

export const muiTheme = getMuiTheme();
