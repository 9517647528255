import * as React from 'react';
import {
    BrowserRouter, Routes, Route, Navigate, useLocation
} from 'react-router-dom';
import './index.css';
import Footer from '../footer';
import Header from '../header';
import LocationGroups from '../locationGroups';
import SomethingWentWrong from '../somethingWentWrong';
import { setTenantTheme } from '../../theme';
import LookerDashboard from '../looker';

function isNotAuthenticated() {
    const { pathname } = useLocation();
    return pathname === '/login' || pathname === '/reset';
}

function HeaderWrapper(): React.ReactElement | null {
    return isNotAuthenticated() ? null : <Header />;
}

function FooterWrapper(): React.ReactElement | null {
    return isNotAuthenticated() ? null : <Footer />;
}

function App(): React.ReactElement {
    React.useEffect(() => {
        setTenantTheme();
    }, []);
    return (
        <BrowserRouter>
            <div className='app'>
                <div className='app-container'>
                    <HeaderWrapper />
                    <Routes>
                        <Route path='/' element={<LocationGroups />} />
                        <Route path='/location_group/:locationGroupId' element={<LookerDashboard />} />
                        <Route path='/error/:status' element={<SomethingWentWrong />} />
                        <Route path='*' element={<Navigate to='/error/404' />} />
                    </Routes>
                </div>
                <FooterWrapper />
            </div>
        </BrowserRouter>
    );
}

export default App;
