import React from 'react';
import AppLogo from '../appLogo';

type LoaderProps = {
    isLoading: boolean;
};

export default function Loader(props: LoaderProps) {
    const { isLoading } = props;
    if (isLoading) {
        return (
            <span className='progress'>
                <AppLogo />
            </span>
        );
    }
    return null;
}
