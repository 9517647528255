import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import LookerEmbedDashboard from './dashboard/looker';
import { getLocationGroupById } from '../../api';

function LookerDashboard() {
    const navigate = useNavigate();
    const { locationGroupId } = useParams<{ locationGroupId: string }>();
    const [isValidLocationGroupId, setIsValidLocationGroupId] = useState(false);

    const fetchLocationGroup = async () => {
        try {
            const locationGroupData = await getLocationGroupById(Number(locationGroupId));
            if (locationGroupData) {
                setIsValidLocationGroupId(true);
            } else {
                navigate('/error/404', { replace: true });
            }
        } catch (error) {
            console.error('Failed to fetch Location group');
            if (error?.status !== 401) {
                console.error('Failed to fetch Location group');
                navigate(`/error/${error?.status || 500}`, { replace: true });
            }
        }
    };

    useEffect(() => {
        fetchLocationGroup();
    }, [locationGroupId]);

    return (
        <div id='looker-dashboard-im'>
            {isValidLocationGroupId && (
                <LookerEmbedDashboard locationGroupId={locationGroupId || ''} dashboardId={1026} />
            )}
        </div>
    );
}

export default LookerDashboard;
