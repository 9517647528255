import Dompurify from 'dompurify';

export default function findBounds(arr: any[], getCoords: (obj: any) => number[]): number[][] {
    let maxLat = -100;
    let minLat = 100;
    let maxLong = -200;
    let minLong = 200;

    arr.forEach((obj) => {
        const [lat, long] = getCoords(obj);
        maxLat = Math.max(maxLat, lat);
        minLat = Math.min(minLat, lat);
        maxLong = Math.max(maxLong, long);
        minLong = Math.min(minLong, long);
    });

    return [
        [minLong, minLat],
        [maxLong, maxLat]
    ];
}

export const sanitizeHTML = (text: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(Dompurify.sanitize(text), 'text/html');
    return doc.body.textContent;
};

export const isNullOrUndefined = (val: any) => val === null || val === undefined;

export const getDatefromTimestamp = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};
