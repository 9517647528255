import React from 'react';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import LocationGroupCardCell from '../locationGroupCardCell';
import { AuthorizedLGs } from '../../../client';
import { getDatefromTimestamp } from '../../../utils';
import './index.css';

export default function LocationGroupCard(props: { locationGroup: AuthorizedLGs }) {
    const { locationGroup } = props;
    const statusColors: { [key: string]: string } = {
        Enabled: 'var(--color-active-status)',
        Pending: 'var(--color-secondary)',
        Disabled: 'var(--color-paused-status)',
        Expired: 'var(--color-expired-status)',
        default: 'var(--color-on-primary)'
    };
    const getStatusColor = (status: string) => statusColors[status] || statusColors.default;
    return (
        <Link
            className='location-group-card--container'
            to={`/location_group/${locationGroup.id}`}
            data-testid={`test-location-group-${locationGroup.id}`}
        >
            <div className='location-group-card--header'>
                <LocationGroupCardCell header='Start' value={getDatefromTimestamp(locationGroup.created)} />
            </div>

            <div className='location-group-card--body'>
                <div>
                    <div className='location-group-card--title'>Location Group Name</div>
                    <div className='location-group-card--extra-strong'>{locationGroup.name}</div>
                </div>
                <div>
                    <div className='location-group-card--title'>Location Group Id</div>
                    <div className='location-group-card--strong'>{locationGroup.id}</div>
                </div>
            </div>

            <div className='location-group-card--footer'>
                <LocationGroupCardCell
                    header='Status'
                    color={getStatusColor(capitalize(locationGroup.status))}
                    value='Active'
                />
            </div>
        </Link>
    );
}
