/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum SortOrder {
    DESC = 'desc',
    ASC = 'asc',
}
