import axios from 'axios/index';
import Cookies from 'js-cookie';
import config from './config';
import {
    AppClient, AuthorizedLGs, PaginatedAuthorizedLGs, SortColumns, SortOrder
} from './client';

axios.defaults.headers.put['Content-Type'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';

const appClient = new AppClient({
    BASE: `${config.apiHostname}/internal`
});

const redirectToLogin = () => {
    let loginUrl = `${config.loginUrl}${document.location.href}`;
    if (config.env === 'local') {
        loginUrl = loginUrl.replace('localhost:3000', 'localhost.groundtruth.com:3000');
    }
    window.location.replace(loginUrl);
};

// Add a request interceptor to include session header
axios.interceptors.request.use(
    (conf) => {
        // eslint-disable-next-line no-param-reassign
        conf.headers = {
            ...(conf.headers || {}),
            'x-gt-session': Cookies.get('session') || ''
        };
        return conf;
    },
    (error) => Promise.reject(error)
);

// Add a response interceptor to check if user is logged out
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        const errorStatusCode = error.response?.status;
        if (errorStatusCode === 401) {
            redirectToLogin();
        }
        return Promise.reject(error);
    }
);

async function getLocationGroups(
    limit?: number,
    offset?: number,
    sortBy?: SortColumns | undefined,
    sortOrder?: SortOrder
): Promise<PaginatedAuthorizedLGs> {
    const response = await appClient.location_groups.getLocationGroups(limit, offset, sortBy, sortOrder);
    return response;
}

async function getLocationGroupById(locationGroupId: number): Promise<AuthorizedLGs> {
    const response = await appClient.location_groups.getLocationGroupById(locationGroupId);
    return response;
}

async function getUserInfo() {
    const response = await appClient.users.getUserInfo();
    return response;
}

export { getUserInfo, getLocationGroups, getLocationGroupById };
