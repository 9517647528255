import * as React from 'react';
import { Link, useParams } from 'react-router-dom';

import './index.css';
import { ReactComponent as SomethingWentWrongImage } from '../../images/something-wrong.svg';

function SomethingWentWrong(): React.ReactElement {
    const { status } = useParams();

    return (
        <div className='something-went-wrong--container'>
            <div className='something-went-wrong--img-container'>
                <SomethingWentWrongImage />
                <div className='something-went-wrong--status-code'>
                    <p>{status}</p>
                </div>
            </div>
            <div className='something-went-wrong--bottom-content'>
                <p className='something-went-wrong--static-msg'>Oops! Something went wrong</p>
                {status === '404' && <p className='something-went-wrong--error-msg'>Page not found</p>}
                <Link className='something-went-wrong--link' to='/'>
                    <div className='something-went-wrong--button'>Homepage</div>
                </Link>
            </div>
        </div>
    );
}

export default SomethingWentWrong;
