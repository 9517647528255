import './index.css';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { StateProvider } from './store';
import App from './components/app';
import { muiTheme } from './theme/index';
import config from './config';

Sentry.init({
    dsn: config.sentryDSN,
    enabled: config.env !== 'local',
    environment: config.env,
    autoSessionTracking: false
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={muiTheme}>
            <StateProvider>
                <App />
            </StateProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
