import * as React from 'react';
import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getLocationGroups } from '../../api';
import MobileView from './mobileView';
import TableView from './tableView';
import { StateActionType, store } from '../../store';
import { AuthorizedLGs, SortColumns, SortOrder } from '../../client';
// import { ReactComponent as NoResults } from '../../images/no-results.svg';
import './index.css';

function LocationGroups() {
    const debounceWait = 300;
    const mobileOffsetIncrement = 10;
    const isMobile = useMediaQuery('(max-width:1200px)');
    const navigate = useNavigate();
    const [locationGroups, setLocationGroups] = useState<AuthorizedLGs[]>([]);
    const [count, setCount] = useState(0);
    const globalStore: any = React.useContext(store);
    const { dispatch } = globalStore;
    const [searchParam] = useSearchParams();
    const searchValue = globalStore?.state?.searchQuery || searchParam.get('q');
    const initialSortRule = globalStore?.state?.sortRule;
    const rules = Object.values(SortColumns).map((k, i) => ({
        id: i,
        name: k,
        isActive: initialSortRule ? k === initialSortRule?.name : i === 0, // default active sort column is first
        direction: initialSortRule?.direction || SortOrder.ASC
    }));
    const [sortRules, setSortRules] = useState(rules);
    const [activeSortRule, setActiveSortRule] = useState(initialSortRule);
    const [locationGroupLimit, setLocationGroupLimit] = useState(10);
    const [locationGroupOffset, setLocationGroupOffset] = useState(0);
    // Storing last search to avoid race conditions during debounce
    const lastSearch = React.useRef(searchValue);

    /**
     * Helps figuring out which way the sort direction will be
     * when clicking table headers to sort.
     */
    const getSortDirection = (direction: SortOrder, isActive: boolean): SortOrder => {
        if (isActive) {
            return direction === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
        }
        return direction;
    };

    const fetchLocationGroupsData = async ({
        query = searchValue,
        limit = locationGroupLimit,
        offset = locationGroupOffset,
        sortBy = activeSortRule?.name,
        sortOrder = activeSortRule?.direction,
        concat = false
    }) => {
        if (searchValue?.length > 0) {
            localStorage.removeItem('page');
        }
        try {
            dispatch({ type: StateActionType.SET_LOADING, payload: true });
            lastSearch.current = query;
            const locationGroupsData = await getLocationGroups(limit, offset, sortBy, sortOrder);
            if (query !== lastSearch.current) return;
            setCount(locationGroupsData.count);
            if (concat) {
                setLocationGroups([...locationGroups, ...locationGroupsData.locationGroups]);
            } else {
                setLocationGroups(locationGroupsData.locationGroups);
            }
        } catch (error) {
            if (error?.status !== 401) {
                console.error('Failed to fetch location groups', error);
                navigate(`/error/${error?.status || 500}`, { replace: true });
            }
        } finally {
            if (query === lastSearch.current) {
                dispatch({ type: StateActionType.SET_LOADING, payload: false });
            }
        }
    };

    const debounceGetLocationGroupsData = React.useMemo(() => debounce(fetchLocationGroupsData, debounceWait), []);

    useEffect(() => {
        const offset = 0;
        setLocationGroupOffset(offset);
        dispatch({ type: StateActionType.SET_LOADING, payload: true });
        if (lastSearch.current) {
            debounceGetLocationGroupsData({
                query: searchValue, limit: locationGroupLimit, offset
            });
        } else fetchLocationGroupsData({ query: searchValue, limit: locationGroupLimit, offset });
    }, [searchValue]);

    const onSortIconClick = (index: number) => {
        const newSortRules = sortRules.map((rule) => ({
            ...rule,
            name: rule.name,
            isActive: rule.id === index,
            direction: getSortDirection(rule.direction as SortOrder, rule.id === index)
        }));
        setSortRules(newSortRules);

        const newSortRule = newSortRules.find((r) => r.isActive);
        setActiveSortRule(newSortRule!);
        dispatch({ type: StateActionType.SET_SORT_RULE, payload: newSortRule });
        fetchLocationGroupsData({ query: searchValue, sortBy: newSortRule!.name, sortOrder: newSortRule!.direction });
    };

    const navigateToLG = (locationGroupId: Number) => {
        dispatch({ type: StateActionType.SET_SEARCH_QUERY, payload: '' });
        navigate(`/location_group/${locationGroupId}`);
    };

    const onPageChange = (page: number, rowsPerPage: number) => {
        const offset = rowsPerPage * (page - 1);
        setLocationGroupLimit(rowsPerPage);
        setLocationGroupOffset(offset);
        fetchLocationGroupsData({ query: searchValue, limit: rowsPerPage, offset });
    };

    const loadMore = () => {
        if (locationGroups.length >= count) {
            return;
        }
        const offset = locationGroupOffset + mobileOffsetIncrement;
        setLocationGroupOffset(offset);
        fetchLocationGroupsData({
            query: searchValue,
            limit: locationGroupLimit,
            offset,
            concat: true
        });
    };

    const showNoLGsFound = !searchValue && !count;
    const hideTable = !!searchValue && !count;

    return (
        <div className='locationGroups--container'>
            {/* Add search functionality here if needed */}

            {isMobile && (
                <MobileView
                    showNoLGsFound={showNoLGsFound}
                    locationGroups={locationGroups}
                    loadMore={loadMore}
                    isLoading={globalStore.state.isLoading}
                />
            )}

            {!isMobile && !hideTable && (
                <TableView
                    showNoLGsFound={showNoLGsFound}
                    rowCount={count}
                    locationGroups={locationGroups}
                    navigateToLG={navigateToLG}
                    sortRules={sortRules}
                    onSortIconClick={onSortIconClick}
                    onPageChange={onPageChange}
                    isLoading={globalStore.state.isLoading}
                />
            )}
        </div>
    );
}

export default LocationGroups;
