import React from 'react';
import './index.css';

type LocationGroupCardCellPropType = {
    header: string;
    value: string | number;
    color?: string;
};

export default function LocationGroupCardCell(props: LocationGroupCardCellPropType) {
    const { header, value, color } = props;
    const isStatus = header === 'Status';
    return (
        <div className='location-group-card-cell--header'>
            <div className='location-group-card-cell--header'>{header}</div>
            <div
                className={`location-group-card-cell--value location-group-card-cell--hyper-strong ${
                    isStatus ? 'location-group-card-cell--status' : ''
                }`}
                style={{ color }}
            >
                {value}
            </div>
        </div>
    );
}
LocationGroupCardCell.defaultProps = {
    color: '#0A3F36'
};
