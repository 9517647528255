/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedAuthorizedLGs } from '../models/PaginatedAuthorizedLGs';
import type { SortColumns } from '../models/SortColumns';
import type { SortOrder } from '../models/SortOrder';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import { AuthorizedLGs } from '../models/AuthorizedLocationGroups';

export class LocationGroupsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Authorized Location Groups
     * @param limit Number of LGs per request
     * @param offset Number of LGs to skip per request
     * @param sortBy
     * @param sortOrder
     * @param sessionData
     * @returns PaginatedAuthorizedLocationGroups Successful Response
     * @throws ApiError
     */
    public getLocationGroups(
        limit: number = 10,
        offset?: number,
        sortBy?: SortColumns,
        sortOrder?: SortOrder,
        sessionData?: string
    ): CancelablePromise<PaginatedAuthorizedLGs> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/location-group/all',
            headers: {
                'session-data': sessionData,
            },
            query: {
                limit: limit,
                offset: offset,
                sort_by: sortBy,
                sort_order: sortOrder
            },
            errors: {
                422: `Validation Error`
            }
        });
    }

    /**
     * Get Location Group Data
     * @param locationGroupId
     * @param sessionData
     * @returns AuthorizedLGs Successful Response
     * @throws ApiError
     */
    public getLocationGroupById(
        locationGroupId: number,
        sessionData?: string,
    ): CancelablePromise<AuthorizedLGs> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/location-group/{locationGroupId}',
            path: {
                'locationGroupId': locationGroupId,
            },
            headers: {
                'session-data': sessionData,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
