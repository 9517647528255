import { useEffect } from 'react';
import { LookerEmbedSDK } from '@looker/embed-sdk';
import Cookies from 'js-cookie';
import config from '../../../config';
import './looker.css';

const LookerConfig: LookerConfigs = {
    ...config.lookerConfig
};

function LookerEmbedDashboard({ dashboardId, locationGroupId }: { dashboardId: number; locationGroupId: string }) {
    const dId = dashboardId || LookerConfig.dashboardId;

    const intializeLooker = () => {
        // TODO: remove this session data. Make custom hook
        // also add the session data to the LookerConfig i.e X-gt-session
        // Initialize the Looker Embed SDK
        LookerEmbedSDK.init(LookerConfig.host, {
            url: `${config.apiHostname}/internal/looker/auth`,
            headers: [
                { name: 'app', value: config.backend_app },
                { name: 'key', value: config.backend_key },
                { name: 'x-gt-session', value: Cookies.get('session') || '' }
            ],
            params: [{
                name: 'locationGroupId',
                value: locationGroupId
            }]
        });
    };

    const updateStatus = (selector: string, state: string) => {
        const stateElement = document.querySelector(selector);
        if (stateElement) {
            stateElement.textContent = state;
        }
    };

    const preventNavigation = (event: any): any => {
        const preventNav = true;
        if (preventNav) {
            updateStatus('#dashboard-state', `${event.label} clicked`);
            return { cancel: !event.modal };
        }
        return {};
    };

    const createDashboardWithId = (id: number, lookerConfig: LookerConfigs) => {
        LookerEmbedSDK.createDashboardWithId(id)
            .withDialogScroll(lookerConfig.withDialogScroll)
            .withDynamicIFrameHeight(lookerConfig.withDynamicIFrameHeight)
            .withScrollMonitor(lookerConfig.withScrollMonitor)
            .withAllowAttr(lookerConfig.withAllowAttr)
            .appendTo(lookerConfig.appendTo)
            .withClassName(lookerConfig.withClassName)
            .on('dashboard:loaded', () => updateStatus('#dashboard-state', 'Loaded'))
            .on('drillmenu:click', preventNavigation)
            .on('drillmodal:explore', preventNavigation)
            .on('drillmodal:dashboard', preventNavigation)
            .on('drillmodal:look', preventNavigation)
            .build()
            .connect()
            .then(() => {
            })
            .catch((error) => {
                console.error('Error embedding dashboard', error);
            });
    };

    useEffect(() => {
        // Initialize Looker Embed SDK
        intializeLooker();

        const lookerContainer = document.getElementById('looker-dashboard-im');
        if (lookerContainer) {
            console.log(lookerContainer.innerHTML);
            lookerContainer.innerHTML = '';
        }

        // Create the dashboard
        createDashboardWithId(dId, LookerConfig);
    }, [dashboardId, LookerConfig]);

    return null;
}

export default LookerEmbedDashboard;
